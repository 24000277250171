import React, { useState } from 'react'
import styles from './PortalTable.module.css'
import { axiosConfig } from 'utils/axiosConfig'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import TableLoading from 'components/Global/Elements/TableLoading/TableLoading'

function PortalContactUsTable({userData, getData, isLoadingData}) {
    const [showAddUser, setShowAddUser] = useState(false);
    const [user,setuser] =useState([])

    const handleCloseAddUser = () => {
        setShowAddUser(false)
        setuser([])
    };
    const handleShowAddUser = (user) => {
        setShowAddUser(true)
        setuser(user)
    };
    const deleteUser =(userId)=>{
        axiosConfig.delete(`user/delete-user/${userId}`,{
            headers: {"Authorization":`Bearer ${Cookies.get('token')}`}
        }).then(res=>{
            toast.success('User deleted successfully')
            getData()
        }).catch(err=>{
            // setIsSubmitting(false)
            let errors = err?.response?.data?.data?.[0]
            console.log(err?.response?.data?.data)
            Object.keys(errors)?.forEach(error=>{
                toast.error(errors?.[error])
            })
        })
    }
  return (
    <>
    <div className='table-responsive'>
        <table className={`${styles['table']} table`}>
            <thead>
                <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Subject</th>
                    <th scope="col">Message</th>
                </tr>
            </thead>
            <tbody>
                {
                    !isLoadingData ?
                        userData && userData?.map(user=>(
                            <tr key={user?.id}>
                                <th scope="row">{user?.item_number}</th>
                                <td>
                                    <div className={`${styles['table__user-group']} fw-bold`}>{user?.name}</div>
                                </td>
                                <td>
                                    <div className={`${styles['table__user-group']} text-muted`}>{user?.email}</div>
                                </td>
                                <td>
                                    <div className={`${styles['table__user-group']} text-muted`}>{user?.phone}</div>
                                </td>
                                <td>
                                    <div className={`${styles['table__user-group']}`}>{user?.subject}</div>
                                </td>
                                <td>
                                    <div className={`${styles['table__user-group']}`}>{user?.message}</div>
                                </td>
                            </tr>
                        ))
                    :  <></>
                }
            </tbody>
        </table>
    </div>
    {
        isLoadingData && <TableLoading/>
    }
    </>
  )
}

export default PortalContactUsTable