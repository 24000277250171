import React,{ useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Login from 'views/Login/Login';
import Portal from 'views/Portal/Portal';
import Overview from 'views/PortalPages/Overview/Overview';
import Users from 'views/PortalPages/Users/Users';
import { ToastContainer } from 'react-toastify';
import Authed from 'utils/Authed';
import NotAuthed from 'utils/NotAuthed';
import CategoriesPortal from 'views/PortalPages/Categories/Categories';
import AdminUsers from 'views/PortalPages/AdminUsers/AdminUsers';
import Languages from 'views/PortalPages/Languages/Languages';
import Neighborhoods from 'views/PortalPages/Neighborhoods/Neighborhoods';
import Slider from 'views/PortalPages/Slider/Slider';
import OnboardSlider from 'views/PortalPages/OnboardSlider/OnboardSlider';
import TourGuides from 'views/PortalPages/TourGuides/TourGuides';
import ContactUs from 'views/PortalPages/ContactUs/ContactUs';
import Settings from 'views/PortalPages/Settings/Settings';
import Destinations from 'views/PortalPages/Destinations/Destinations';
import AddEditDestination from 'views/PortalPages/AddEditDestination/AddEditDestination';
import Places from 'views/PortalPages/Places/Places';
import AddEditPlace from 'views/PortalPages/AddEditPlace/AddEditPlace';
import RecommendedPrograms from 'views/PortalPages/RecommendedPrograms/RecommendedPrograms';
import StaticPages from 'views/PortalPages/StaticPages/StaticPages';
import ComparePlace from 'views/PortalPages/ComparePlace/ComparePlace';
function AnimatedRoutes() {
    const [isSideBarVisible , setIsSideBarVisible] =useState(false)
    const location = useLocation()
    const toggleSideNavBar =(type)=> {
        setIsSideBarVisible(type==='open')
    }
    const [isMainNavBarWillApear,setIsMainNavBarWillApear] =useState(false)
    const pagesWithoutNavbar = ['register','register-second','login','forget','test','add-test','preview-add-test','portal', '']

    useEffect(()=>{
        setIsMainNavBarWillApear(! location.pathname.split('/').some(path=>pagesWithoutNavbar.includes(path)) )
    },[location])
  return (
    <>
        <div className="App">
            <ToastContainer />
            <Routes>
                <Route path='/login' element={<NotAuthed><Login/></NotAuthed>}></Route>
                <Route path='/' element={<Authed><Portal/></Authed>}>
                    <Route path='' element={<Overview/>}/>
                    <Route path='admin-users' element={<AdminUsers/>}/>
                    <Route path='users' element={<Users/>}/>
                    <Route path='categories' element={<CategoriesPortal/>}/>
                    <Route path='languages' element={<Languages/>}/>
                    <Route path='neighborhoods' element={<Neighborhoods/>}/>
                    <Route path='slider' element={<Slider/>}/>
                    <Route path='on-board-slider' element={<OnboardSlider/>}/>
                    <Route path='tour-guides' element={<TourGuides/>}/>
                    <Route path='contact-us' element={<ContactUs/>}/>
                    <Route path='settings' element={<Settings/>}/>
                    <Route path='static-pages' element={<StaticPages/>}/>
                    <Route path='recommended-programs' element={<RecommendedPrograms/>}/>
                    <Route path='compare-places' element={<ComparePlace/>}/>

                    <Route path='destinations' element={<Destinations/>}/>
                    <Route path='add-destination' element={<AddEditDestination/>}/>
                    <Route path='edit-destination/:id' element={<AddEditDestination/>}/>

                    <Route path='places' element={<Places/>}/>
                    <Route path='add-place' element={<AddEditPlace/>}/>
                    <Route path='edit-place/:id' element={<AddEditPlace/>}/>

                </Route>
            </Routes>
        </div>
    </>
  )
}

export default AnimatedRoutes