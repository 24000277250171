import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import styles from './AddEditModal.module.css'
import {ReactComponent as ExitIcon} from 'assets/icons/exit.svg'
import { useForm } from 'react-hook-form'
import { emailPattern } from 'utils/features'
import { axiosConfig } from 'utils/axiosConfig'
import { toast } from 'react-toastify'
import ButtonsLoading from 'components/Global/Elements/ButtonsLoading/ButtonsLoading'
import Cookies from 'js-cookie'

function AddEditUserModal({handleCloseAddUser ,showAddUser,user,getData}) {
    const {register ,setError ,getValues,handleSubmit,setValue,formState:{errors}} = useForm({validate:'onChange'})
    const [isSubmitting , setIsSubmitting]=useState(false)
    const submitForm =(data)=>{
        let formData =new FormData()
        Object.keys(getValues()).forEach(key=>{
            formData.append(`${key}`,getValues()[key])
        })
        // formData.append('exam_id',params.id)
        // formData.append('user_id',user.id)
        // formData.append('type',1)
        setIsSubmitting(true)
        if(user){
            if(getValues().password){
                if(getValues().password != getValues().confirm_password){
                    setError('password', { type: 'custom', message: "Confirm password does't equal password" });
                    setError('confirm_password', { type: 'custom', message: "Confirm password does't equal password" });
                    setIsSubmitting(false)
                    return;
                }
            }
            axiosConfig.put(`user/update-user-by-admin/${user?.id}`,formData,{
                headers: {"Authorization":`Bearer ${Cookies.get('token')}`}
            }).then(res=>{
                toast.success('User Updated Successfully')
                handleCloseAddUser()
                setIsSubmitting(false)
                getData()
            }).catch(err=>{
                toast.error(err?.response?.data?.message||'Something went wrong')
                // handleCloseAddUser()
                setIsSubmitting(false)
            })
        }else{
            axiosConfig.post(`user/create-user`,formData,{
                headers: {"Authorization":`Bearer ${Cookies.get('token')}`}
            }).then(res=>{
                toast.success('User Added Successfully')
                handleCloseAddUser()
                setIsSubmitting(false)
                getData()
            }).catch(err=>{
                // toast.error(err?.response?.data?.message||'Something went wrong')
                let errors = err.response.data?.data?.[0]
                console.log(err.response.data?.data)
                Object.keys(errors).forEach(error=>{
                    toast.error(errors[error])
                })
                // handleCloseAddUser()
                setIsSubmitting(false)
            })

        }
    }
    useEffect(()=>{
        setValue('first_name',user?.first_name)
        setValue('last_name',user?.last_name)
        setValue('email',user?.email)
        setValue('phone_number',user?.phone_number)
        setValue('status',user?.status)
        // setValue('description',group?.description)
    }, [user])
    // useEffect(()=>{
    //     setValue('group_id',searchParams?.get('group'))
    // },[searchParams])
  return (
    <>
        <Modal show={showAddUser} onHide={handleCloseAddUser}  size="lg">
            <div className={`${styles["modal-header"]} modal-header`}>
                <h2 className={styles['modal__title']}>{user?'Update':'Add'} User</h2>
                <button type="button" className={`${styles["close"]} close ms-auto`} onClick={handleCloseAddUser}>
                    <ExitIcon className={styles['modal__exit-icon']}/>
                </button>
            </div>
            <form onSubmit={handleSubmit(submitForm)}>
                <div className={`${styles["modal-body"]} modal-body`}>
                    <Row>
                        <Col lg='6' xs='12' className='mb-3'>
                            <div className={styles['modal__form-input-wrapper']}>
                                <label className={styles['modal__form-input-label']} htmlFor='userFirstNameInput'>
                                    First Name <span className={styles['modal__form-input-required']}>*</span>
                                </label>
                                <input 
                                    type='text' 
                                    className={`${styles['modal__form-input']} ${errors?.name ?styles['modal__form-input--error']:''}`} 
                                    placeholder='Please enter first name' 
                                    id='userFirstNameInput'
                                    {...register('first_name',{required:'name is required'})}
                                />
                                {errors?.first_name &&<span className={styles['modal__form-input-error-message']}>{errors.first_name?.message}</span>}
                            </div>
                        </Col>
                        <Col lg='6' xs='12' className='mb-3'>
                            <div className={styles['modal__form-input-wrapper']}>
                                <label className={styles['modal__form-input-label']} htmlFor='userLastNameInput'>
                                    Last Name <span className={styles['modal__form-input-required']}>*</span>
                                </label>
                                <input 
                                    type='text' 
                                    className={`${styles['modal__form-input']} ${errors?.name ?styles['modal__form-input--error']:''}`} 
                                    placeholder='Please enter last name' 
                                    id='userLastNameInput'
                                    {...register('last_name',{required:'name is required'})}
                                />
                                {errors?.last_name &&<span className={styles['modal__form-input-error-message']}>{errors.last_name?.message}</span>}
                            </div>
                        </Col>
                        <Col lg='6' xs='12' className='mb-3'>
                            <div className={styles['modal__form-input-wrapper']}>
                                <label className={styles['modal__form-input-label']} htmlFor='userPhoneNumberInput'>
                                    Phone Number <span className={styles['modal__form-input-required']}>*</span>
                                </label>
                                <input 
                                    type='text' 
                                    className={`${styles['modal__form-input']} ${errors?.name ?styles['modal__form-input--error']:''}`} 
                                    placeholder='Please enter phone number' 
                                    id='userPhoneNumberInput'
                                    {...register('phone_number',{required:'Phone number is required'})}
                                />
                                {errors?.phone_number &&<span className={styles['modal__form-input-error-message']}>{errors.phone_number?.message}</span>}
                            </div>
                        </Col>
                        <Col lg='6' xs='12' className='mb-3'>
                            <div className={styles['modal__form-input-wrapper']}>
                                <label className={styles['modal__form-input-label']} htmlFor='emailInput'>
                                    Email <span className={styles['modal__form-input-required']}>*</span>
                                </label>
                                <input 
                                    type='email' 
                                    className={`${styles['modal__form-input']} ${errors?.email ?styles['modal__form-input--error']:''}`}  
                                    placeholder='Please enter email' 
                                    id='emailInput'
                                    {...register('email',{required:'Email is required',
                                    pattern:{
                                        value:emailPattern,
                                        message:'Email must be like invo***@academy**.***'
                                    }})}
                                />
                                {errors?.email &&<span className={styles['modal__form-input-error-message']}>{errors.email?.message}</span>}
                            </div>
                        </Col>
                        <Col lg='6' xs='12' className='mb-3'>
                            <div className={styles['modal__form-input-wrapper']}>
                                <label className={styles['modal__form-input-label']} htmlFor='passwordInput'>
                                    Password <span className={styles['modal__form-input-required']}>*</span>
                                </label>
                                {
                                    user ?
                                    <input 
                                        type='password' 
                                        className={`${styles['modal__form-input']} ${errors?.password ?styles['modal__form-input--error']:''}`}  
                                        placeholder='Please enter password' 
                                        id='passwordInput'
                                        {...register('password')
                                        // ,{required:'Password is required'})
                                    }
                                    />
                                    :
                                    <input 
                                        type='password' 
                                        className={`${styles['modal__form-input']} ${errors?.password ?styles['modal__form-input--error']:''}`}  
                                        placeholder='Please enter password' 
                                        id='passwordInput'
                                        {...register('password',{required:'Password is required'})}
                                    />
                                }
                                {errors?.password &&<span className={styles['modal__form-input-error-message']}>{errors.password?.message}</span>}
                            </div>
                        </Col>
                        <Col lg='6' xs='12' className='mb-3'>
                            <div className={styles['modal__form-input-wrapper']}>
                                <label className={styles['modal__form-input-label']} htmlFor='confirmPasswordInput'>
                                    Confirm Password <span className={styles['modal__form-input-required']}>*</span>
                                </label>
                                {
                                    user?
                                    <input 
                                        type='password' 
                                        className={`${styles['modal__form-input']} ${errors?.confirm_password ?styles['modal__form-input--error']:''}`}  
                                        placeholder='Please enter confirm password' 
                                        id='confirmPasswordInput'
                                        {...register('confirm_password',
                                        // {required:'Confirm password is required' ,validate:{
                                        //     checkPasswordEqualToConfirmPassword :(value)=>{
                                        //         let pass = getValues().password
                                        //         return pass ===value || "Confirm password does't equal password"
                                        //     }
                                        // }}
                                        )}
                                    />
                                    :
                                    <input 
                                        type='password' 
                                        className={`${styles['modal__form-input']} ${errors?.confirm_password ?styles['modal__form-input--error']:''}`}  
                                        placeholder='Please enter confirm password' 
                                        id='confirmPasswordInput'
                                        {...register('confirm_password',
                                        {required:'Confirm password is required' ,validate:{
                                            checkPasswordEqualToConfirmPassword :(value)=>{
                                                let pass = getValues().password
                                                return pass ===value || "Confirm password does't equal password"
                                            }
                                        }})}
                                    />
                                }
                                {errors?.confirm_password &&<span className={styles['modal__form-input-error-message']}>{errors.confirm_password?.message}</span>}
                            </div>
                        </Col>
                        {/* <Col lg='6' xs='12' className='mb-3'>
                            <div className={styles['modal__form-input-wrapper']}>
                                <label className={styles['modal__form-input-label']} htmlFor='confirmPasswordInput'>
                                    Academy
                                </label>
                                <select
                                    className={`${styles['modal__form-input']} px-2 ${errors?.group_id ?styles['modal__form-input--error']:''}`} 
                                    {...register('group_id')}
                                >
                                    <option value=''>Please Select Academy</option>
                                    {
                                        groups && groups?.map(group=>(
                                            <option value={group?.id} key={group?.id}>{group?.title_en}</option>
                                        ))
                                    }
                                </select>
                                {errors?.group_id &&<span className={styles['modal__form-input-error-message']}>{errors.group_id?.message}</span>}
                            </div>
                        </Col> */}
                        <Col lg='6' xs='12' className='mb-3'>
                            <div className={styles['modal__form-input-wrapper']}>
                                <label className={styles['modal__form-input-label']} htmlFor='confirmPasswordInput'>
                                    Status <span className={styles['modal__form-input-required']}>*</span>
                                </label>
                                <select
                                    className={`${styles['modal__form-input']} px-2 ${errors?.status ?styles['modal__form-input--error']:''}`} 
                                    {...register('status',{required:'User Status is required'})}
                                >
                                    <option value='1' selected>Active</option>
                                    <option value='2'>Not Active</option>
                                </select>
                                {errors?.status &&<span className={styles['modal__form-input-error-message']}>{errors.status?.message}</span>}
                            </div>
                        </Col>
                    </Row>
                    <div className={styles['submit-modal__buttons-cont']}>
                        <button type='submit' className={styles['submit-modal__confirm-button']} disabled={isSubmitting}>{isSubmitting?<ButtonsLoading/>:'Submit'}</button>
                    </div>
                </div>
            </form>

        </Modal>
    </>
  )
}

export default AddEditUserModal