import React, { useState } from 'react'
import AddEditUserModal from '../AddEditModal/AddEditModal';
import {ReactComponent as AddIcon} from 'assets/icons/addIcon.svg'
import styles from './TableHeader.module.css'
function AdminUsersTableHeader({getData}) {
    const [showAddUser, setShowAddUser] = useState(false);

    const handleCloseAddUser = () => setShowAddUser(false);
    const handleShowAddUser = () => setShowAddUser(true);
  return (
    // <div className={styles['table__header-wrapper']}>
    <>
        {/* <h1 className={styles['table__title']}>Students</h1> */}
        <button className={styles['table__add-button']} onClick={handleShowAddUser}>
            <AddIcon className={styles['table__add-icon']}/>Add Admin User
        </button>
        <AddEditUserModal showAddUser={showAddUser} handleCloseAddUser={handleCloseAddUser} getData={getData}/>
    </>
    // </div>
  )
}

export default AdminUsersTableHeader