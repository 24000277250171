import React, { useEffect, useState } from 'react'
import { Modal, Row } from 'react-bootstrap'
import styles from './AddEditModal.module.css'
import {ReactComponent as ExitIcon} from 'assets/icons/exit.svg'
import { FormProvider, useForm } from 'react-hook-form'
import { axiosConfig } from 'utils/axiosConfig'
import { toast } from 'react-toastify'
import ButtonsLoading from 'components/Global/Elements/ButtonsLoading/ButtonsLoading'
import Cookies from 'js-cookie'
import MultiLanguageTitleInput from 'components/Global/Elements/MultiLanguageTitleInput/MultiLanguageTitleInput'

function AddEditNeighborhoodsModal({handleCloseAddCategory ,showAddCategory,category,getData}) {
    const methods = useForm({validate:'onChange'})
    const {register ,getValues,handleSubmit,setValue,formState:{errors}} = methods
    const [isSubmitting , setIsSubmitting]=useState(false)
    const submitForm =(data)=>{
        let formData =new FormData()
        // Object.keys(getValues()).forEach(key=>{
        //     formData.append(`${key}`,getValues()[key])
        // })
        formData.append(`title_en`,getValues()?.['titles']?.['en'])
        formData.append(`title_ar`,getValues()?.['titles']?.['ar'])
        formData.append(`title_fr`,getValues()?.['titles']?.['fr'])
        formData.append(`title_de`,getValues()?.['titles']?.['de'])
        formData.append(`title_es`,getValues()?.['titles']?.['es'])
        formData.append(`title_tr`,getValues()?.['titles']?.['tr'])
        // formData.append(`color`,getValues()['color'])  
        // formData.append(`show_order_number`,getValues()['showOrderNumber'])  
        // formData.append(`image`,image)

        setIsSubmitting(true)
        if(category){
            
            axiosConfig.put(`neighborhood/update-neighborhood/${category?.id}`,formData,{
                headers: {"Authorization":`Bearer ${Cookies.get('token')}`}
            }).then(res=>{
                toast.success('Location Updated Successfully')
                handleCloseAddCategory()
                // setImage([])
                setIsSubmitting(false)
                getData()
            }).catch(err=>{
                setIsSubmitting(false)
                let errors = err?.response?.data?.data?.[0]
                console.log(err?.response?.data?.data)
                Object.keys(errors)?.forEach(error=>{
                    toast.error(errors?.[error])
                })
            })
        }else{
            axiosConfig.post('neighborhood/create-neighborhood',formData,{
                headers: {"Authorization":`Bearer ${Cookies.get('token')}`}
            }).then(res=>{
                toast.success('Location Added Successfully')
                handleCloseAddCategory()
                // setImage([])
                setIsSubmitting(false)
                getData()
            }).catch(err=>{
                // setIsSubmitting(false)
                let errors = err?.response?.data?.data?.[0]
                console.log(err?.response?.data?.data)
                Object.keys(errors)?.forEach(error=>{
                    toast.error(errors?.[error])
                })
            })

        }
    }
    useEffect(()=>{
        setValue('titles',category?.titles)
        // setValue('color',category?.color)
        // setValue('showOrderNumber',category?.show_order_number)
        // setImage(category?.image)
    }, [category])
   
  return (
    <>
        <Modal show={showAddCategory} onHide={handleCloseAddCategory}  size="lg">
            <div className={`${styles["modal-header"]} modal-header`}>
                <h2 className={styles['modal__title']}>{category?'Update':'Add'} Location</h2>
                <button type="button" className={`${styles["close"]} close ms-auto`} onClick={handleCloseAddCategory}>
                    <ExitIcon className={styles['modal__exit-icon']}/>
                </button>
            </div>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(submitForm)}>
                    <div className={`${styles["modal-body"]} modal-body`}>
                        <Row>
                            <MultiLanguageTitleInput/>

                            {/* <Col lg='6' xs='12' className='mb-3'>
                                <div className={styles['modal__form-input-wrapper']}>
                                    <label className={styles['modal__form-input-label']} htmlFor='colorInput'>
                                        Color <span className={styles['modal__form-input-required']}>*</span>
                                    </label>
                                    <input 
                                        type='color' 
                                        className={`${styles['modal__form-input']} w-100 ${errors?.color ?styles['modal__form-input--error']:''}`} 
                                        placeholder='Please enter Category Color' 
                                        id='colorInput'
                                        {...register('color',{required:'Category Color is required'})}
                                    />
                                    {errors?.color &&<span className={styles['modal__form-input-error-message']}>{errors.color?.message}</span>}
                                </div>
                            </Col> */}
                            {/* <Col lg='6' xs='12' className='mb-3'>
                                <div className={styles['modal__form-input-wrapper']}>
                                    <label className={styles['modal__form-input-label']} htmlFor='showOrderNumberInput'>
                                        Listing Number <span className={styles['modal__form-input-required']}>*</span>
                                    </label>
                                    <input 
                                        type='text' 
                                        className={`${styles['modal__form-input']} ${errors?.showOrderNumber ?styles['modal__form-input--error']:''}`} 
                                        placeholder='Please enter Category Title' 
                                        id='showOrderNumberInput'
                                        {...register('showOrderNumber',{required:'Category Title is required'})}
                                    />
                                    {errors?.showOrderNumber &&<span className={styles['modal__form-input-error-message']}>{errors.showOrderNumber?.message}</span>}
                                </div>
                            </Col> */}

                            {/* <Col lg='6' xs='12' className='mb-3'>
                                <div className={styles['modal__form-input-wrapper']}>
                                    <label className={styles['modal__form-input-label']} htmlFor='confirmPasswordInput'>
                                        Status <span className={styles['modal__form-input-required']}>*</span>
                                    </label>
                                    <select
                                        // className='form-select mt-3'
                                        className={`${styles['modal__form-input']} px-2 ${errors?.status ?styles['modal__form-input--error']:''}`} 
                                        {...register('status',{required:'Category Status is required'})}
                                    >
                                        <option value=''>Please Select Active Status</option>
                                        <option value='1' selected>Active</option>
                                        <option value='2'>Not Active</option>
                                    </select>
                                    {errors?.status &&<span className={styles['modal__form-input-error-message']}>{errors.status?.message}</span>}
                                </div>
                            </Col> */}
                            {/* <Col xs='12' className='mb-3'>
                                <div className={styles['modal__form-input-wrapper']}>
                                    <label className={styles['modal__form-input-label']} htmlFor='confirmPasswordInput'>
                                        Parent Category
                                    </label>
                                    <select
                                        className={`${styles['modal__form-input']} px-2 ${errors?.parentCategory ?styles['modal__form-input--error']:''}`} 
                                        {...register('parentCategory')}
                                    >
                                        <option value=''>Please Select Parent Category</option>
                                        {
                                            categories && categories?.map(category=>(
                                                <option value={category?.id} key={category?.id}>{category?.title}</option>
                                            ))
                                        }
                                    </select>
                                    {errors?.parentCategory &&<span className={styles['modal__form-input-error-message']}>{errors.parentCategory?.message}</span>}
                                </div>
                            </Col> */}
                            {/* <Col xs='12' className='mb-3'>
                                <div className={styles['modal__form-input-wrapper']}>
                                    <label className={styles['modal__form-input-label']} htmlFor='confirmPasswordInput'>
                                        Image 
                                    </label>
                                    <input
                                        type='file'
                                        data-kt-user-table-filter='search'
                                        className='form-control form-control-solid mb-3 ps-14'
                                        placeholder='Type Icon'
                                        id='carImage'
                                        onChange={(e) => handleUploadedImage(e)}
                                        accept={'.jpg,.png,.gif,.jpeg'}
                                    />
                                    {
                                        image&&(image?.preview||image?.length!=0)&&
                                            <div>
                                                <div className='mb-2 d-flex align-items-center justify-content-between'>
                                                    <img src={image?.preview ?image?.preview:image} alt='img' style={{width:'100px',height:'100px'}}/>
                                                    <button onClick={()=>{setImage(null)}}
                                                    className='btn btn-danger ms-auto'>Delete</button>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </Col> */}
                        </Row>
                        <div className={styles['submit-modal__buttons-cont']}>
                            <button type='submit' className={styles['submit-modal__confirm-button']} disabled={isSubmitting}>{isSubmitting?<ButtonsLoading/>:'Submit'}</button>
                        </div>
                    </div>
                </form>
            </FormProvider>

        </Modal>
    </>
  )
}

export default AddEditNeighborhoodsModal