import React from 'react'
import styles from './LoginHeader.module.css'
import logo from 'assets/imgs/logo.png'

function LoginHeader() {
  return (
    <div className={styles["login__form-head"]}>
      {/* <NavLink to='/' className={styles["login__return-para"]}>
        <LeftAngle className={styles["login__return-icon"]}/>
        <span className={styles["login__return-span"]}>Back To Home</span>
      </NavLink> */}
      <img src={logo} alt='logo' className={styles["login__logo"]}/>
      {/* <InvoLogoRegister className={styles["login__logo"]}/> */}
    </div>
  )
}

export default LoginHeader