import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import styles from './AddEditModal.module.css'
import { Controller, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import ButtonsLoading from 'components/Global/Elements/ButtonsLoading/ButtonsLoading'
import Cookies from 'js-cookie'
import { axiosConfig } from 'utils/axiosConfig'
import { MultiSelect } from 'react-multi-select-component'

function AddEditSettingsForm({ settings, getData, categories }) {
    const { register, control, handleSubmit, getValues, setValue, formState: { errors } } = useForm({ validate: 'onChange' })
    const [isSubmitting, setIsSubmitting] = useState(false)
    
    const [options,setOptions] = useState([]);
    useEffect(()=>{
        let options = categories?.map(category=>{
            return { label:category?.title, value:category?.id }
        })
        setOptions(options)
    }, [categories])

    const submitForm = (data) => {
        setIsSubmitting(true)
        let formData =new FormData()
        Object.keys(getValues()).forEach(key=>{
            if (key !== 'categories') formData.append(`${key}`,getValues()[key])
        })
        getValues('categories')?.forEach((category, index)=>{
            console.log("getValues('categories')", category)
            formData.append(`categories[${index}]`, category?.value)
        }) 
        axiosConfig.put('settings/update-settings', formData, {
            headers: { "Authorization": `Bearer ${Cookies.get('token')}` }
        }).then(res => {
            toast.success('Settings Updated Successfully')
            setIsSubmitting(false)
            getData()
        }).catch(err => {
            toast.error(err?.response?.data?.message || 'Something went wrong')
            setIsSubmitting(false)
        })
    }

    useEffect(() => {
        if (settings) {
            setValue("is_project_In_factory_mode", settings?.is_project_In_factory_mode)
            setValue("project_whats_app_number", settings?.project_whats_app_number)
            setValue("project_phone_number", settings?.project_phone_number)
            setValue("project_email_address", settings?.project_email_address)
            setValue("project_facebook_link", settings?.project_facebook_link)
        }
    }, [settings])

    useEffect(()=>{
        if (settings && categories?.length > 0) {
            let values = categories?.map((category) => {
                if (settings?.categories?.includes(category?.id)) {
                    return { label:category?.title, value:category?.id }
                }
            })?.filter(lang=>lang)
            setValue('categories',values)
        }
        // setValue('description',group?.description)
    }, [settings, categories])

    return (
        <div className='table-responsive'>
            <div className={`${styles["modal-header"]} modal-header`}>
                <h2 className={styles['modal__title']}>Update General Settings</h2>
            </div>
            <form onSubmit={handleSubmit(submitForm)}>
                <div className={`${styles["modal-body"]} modal-body`}>
                    <Row>
                        <Col lg='6' xs='12' className='mb-3'>
                        <div className={styles['modal__form-input-wrapper']}>
                            <label className={styles['modal__form-input-label']} htmlFor='isProjectInFactoryMode'>
                                Factory Mode <span className={styles['modal__form-input-required']}>*</span>
                            </label>
                            <select
                                className={`${styles['modal__form-input']} ${errors?.is_project_In_factory_mode ? styles['modal__form-input--error'] : ''}`}
                                id='isProjectInFactoryMode'
                                placeholder='Please enter Category Color' 
                                {...register('is_project_In_factory_mode', { required: 'Factory mode is required' })}
                            >
                                <option value='1'>Not In Factory Mode</option>
                                <option value='2'>In Factory Mode</option>
                            </select>
                            {errors?.is_project_In_factory_mode && <span className={styles['modal__form-input-error-message']}>{errors.is_project_In_factory_mode?.message}</span>}
                        </div>
                        </Col>
                        <Col lg='6' xs='12' className='mb-3'>
                            <div className={`${styles['modal__form-input-wrapper']} h-100 `} id='langugeWrapperid'>
                                <label className={styles['modal__form-input-label']} htmlFor='languagesInput'>
                                    Categories
                                </label>
                                    <Controller 
                                        render={({name })=><MultiSelect
                                            className='h-100'
                                            name={name}
                                            options={options}
                                            value={getValues(`categories`)||[]}
                                            onChange={(data)=>  setValue(`categories`,data)}  
                                            labelledBy="Select"
                                        />}
                                        name='categories'
                                        control={control}
                                    />
                                {/* {errors?.categories &&<span className={styles['modal__form-input-error-message']}>{errors.categories?.message}</span>} */}
                            </div>
                        </Col>

                        <Col lg='6' xs='12' className='mb-3'>
                        <div className={styles['modal__form-input-wrapper']}>
                            <label className={styles['modal__form-input-label']} htmlFor='projectWhatsAppNumber'>
                                WhatsApp Number
                            </label>
                            <input
                                type='text'
                                className={`${styles['modal__form-input']} ${errors?.project_whats_app_number ? styles['modal__form-input--error'] : ''}`}
                                id='projectWhatsAppNumber'
                                placeholder='Please enter WhatsApp Number' 
                                {...register('project_whats_app_number')}
                            />
                        </div>
                        </Col>

                        <Col lg='6' xs='12' className='mb-3'>
                            <div className={styles['modal__form-input-wrapper']}>
                                <label className={styles['modal__form-input-label']} htmlFor='projectPhoneNumber'>
                                    Phone Number
                                </label>
                                <input
                                    type='text'
                                    className={`${styles['modal__form-input']} ${errors?.project_phone_number ? styles['modal__form-input--error'] : ''}`}
                                    id='projectPhoneNumber'
                                    placeholder='Please enter Phone Number' 
                                    {...register('project_phone_number')}
                                />
                            </div>
                        </Col>

                        <Col lg='6' xs='12' className='mb-3'>
                            <div className={styles['modal__form-input-wrapper']}>
                                <label className={styles['modal__form-input-label']} htmlFor='projectEmailAddress'>
                                    Email Address
                                </label>
                                <input
                                    type='email'
                                    className={`${styles['modal__form-input']} ${errors?.project_email_address ? styles['modal__form-input--error'] : ''}`}
                                    id='projectEmailAddress'
                                    placeholder='Please enter Email Address' 
                                    {...register('project_email_address')}
                                />
                            </div>
                        </Col>

                        <Col lg='6' xs='12' className='mb-3'>
                        <div className={styles['modal__form-input-wrapper']}>
                            <label className={styles['modal__form-input-label']} htmlFor='projectFacebookLink'>
                                Facebook Link
                            </label>
                            <input
                                type='url'
                                className={`${styles['modal__form-input']} ${errors?.project_facebook_link ? styles['modal__form-input--error'] : ''}`}
                                id='projectFacebookLink'
                                placeholder='Please enter Facebook Link' 
                                {...register('project_facebook_link')}
                            />
                        </div>
                        </Col>
                        {/* <Col xs='12' className='mb-3'>
                            <div className={styles['modal__form-input-wrapper']}>
                                <label className={styles['modal__form-input-label']} htmlFor='confirmPasswordInput'>
                                Project Logo 
                                </label>
                                <input
                                    type='file'
                                    data-kt-user-table-filter='search'
                                    className='form-control form-control-solid mb-3 ps-14'
                                    placeholder='Type Icon'
                                    id='carImage'
                                    onChange={(e) => handleUploadedImage(e)}
                                    accept={'.jpg,.png,.gif,.jpeg'}
                                />
                                {
                                    image&&(image?.preview||image?.length!=0)&&
                                        <div>
                                            <div className='mb-2 d-flex align-items-center justify-content-between'>
                                                <img src={image?.preview ?image?.preview:image} alt='img' style={{width:'100px',height:'100px'}}/>
                                                <button onClick={()=>{setImage(null)}}
                                                className='btn btn-danger ms-auto'>Delete</button>
                                            </div>
                                        </div>
                                }
                            </div>
                        </Col> */}
                    </Row>
                </div>
                <div className={styles['submit-modal__buttons-cont']}>
                    <button type='submit' className={styles['submit-modal__confirm-button']} disabled={isSubmitting}>{isSubmitting?<ButtonsLoading/>:'Submit'}</button>
                </div>
            </form>
        </div>
    )
}

export default AddEditSettingsForm;
