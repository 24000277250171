import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Col } from 'react-bootstrap'; // Assuming you are using react-bootstrap for the Col component
import styles from './MultiLanguageTitleInput.module.css'; // Replace with your actual CSS module path

const MultiLanguageTitleInput = () => {
  const { register, formState: { errors } } = useFormContext(); // Access form context

  return (
    <>
      <Col lg='6' xs='12' className='mb-3'>
        <div className={styles['modal__form-input-wrapper']}>
          <label className={styles['modal__form-input-label']} htmlFor='titleEn'>
            English Title <span className={styles['modal__form-input-required']}>*</span>
          </label>
          <input
            type='text'
            className={`${styles['modal__form-input']} ${errors?.titles?.en ? styles['modal__form-input--error'] : ''}`}
            placeholder='Please enter English Title'
            id='titleEn'
            {...register('titles.en', { required: 'English title is required' })}
          />
          {errors?.titles?.en && <span className={styles['modal__form-input-error-message']}>{errors?.titles?.en.message}</span>}
        </div>
      </Col>

      <Col lg='6' xs='12' className='mb-3'>
        <div className={styles['modal__form-input-wrapper']}>
          <label className={styles['modal__form-input-label']} htmlFor='titleAr'>
            Arabic Title <span className={styles['modal__form-input-required']}>*</span>
          </label>
          <input
            type='text'
            className={`${styles['modal__form-input']} ${errors?.titles?.ar ? styles['modal__form-input--error'] : ''}`}
            placeholder='Please enter Arabic Title'
            id='titleAr'
            {...register('titles.ar', { required: 'Arabic title is required' })}
          />
          {errors?.titles?.ar && <span className={styles['modal__form-input-error-message']}>{errors?.titles?.ar?.message}</span>}
        </div>
      </Col>

      <Col lg='6' xs='12' className='mb-3'>
        <div className={styles['modal__form-input-wrapper']}>
          <label className={styles['modal__form-input-label']} htmlFor='titleFr'>
            French Title <span className={styles['modal__form-input-required']}>*</span>
          </label>
          <input
            type='text'
            className={`${styles['modal__form-input']} ${errors?.titles?.fr ? styles['modal__form-input--error'] : ''}`}
            placeholder='Please enter French Title'
            id='titleFr'
            {...register('titles.fr', { required: 'French title is required' })}
          />
          {errors?.titles?.fr && <span className={styles['modal__form-input-error-message']}>{errors?.titles?.fr?.message}</span>}
        </div>
      </Col>

      <Col lg='6' xs='12' className='mb-3'>
        <div className={styles['modal__form-input-wrapper']}>
          <label className={styles['modal__form-input-label']} htmlFor='titleDe'>
            German Title <span className={styles['modal__form-input-required']}>*</span>
          </label>
          <input
            type='text'
            className={`${styles['modal__form-input']} ${errors?.titles?.de ? styles['modal__form-input--error'] : ''}`}
            placeholder='Please enter German Title'
            id='titleDe'
            {...register('titles.de', { required: 'German title is required' })}
          />
          {errors?.titles?.de && <span className={styles['modal__form-input-error-message']}>{errors?.titles?.de?.message}</span>}
        </div>
      </Col>

      <Col lg='6' xs='12' className='mb-3'>
        <div className={styles['modal__form-input-wrapper']}>
          <label className={styles['modal__form-input-label']} htmlFor='titleEs'>
            Spanish Title <span className={styles['modal__form-input-required']}>*</span>
          </label>
          <input
            type='text'
            className={`${styles['modal__form-input']} ${errors?.titles?.es ? styles['modal__form-input--error'] : ''}`}
            placeholder='Please enter Spanish Title'
            id='titleEs'
            {...register('titles.es', { required: 'Spanish title is required' })}
          />
          {errors?.titles?.es && <span className={styles['modal__form-input-error-message']}>{errors?.titles?.es?.message}</span>}
        </div>
      </Col>

      <Col lg='6' xs='12' className='mb-3'>
        <div className={styles['modal__form-input-wrapper']}>
          <label className={styles['modal__form-input-label']} htmlFor='titleTr'>
            Turkish Title <span className={styles['modal__form-input-required']}>*</span>
          </label>
          <input
            type='text'
            className={`${styles['modal__form-input']} ${errors?.titles?.tr ? styles['modal__form-input--error'] : ''}`}
            placeholder='Please enter Turkish Title'
            id='titleTr'
            {...register('titles.tr', { required: 'Turkish title is required' })}
          />
          {errors?.titles?.tr && <span className={styles['modal__form-input-error-message']}>{errors?.titles?.tr?.message}</span>}
        </div>
      </Col>
    </>
  );
};

export default MultiLanguageTitleInput;
