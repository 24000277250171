import { ChangeEvent, useCallback, useEffect, useState } from "react";
// import { MetaTags } from 'react-meta-tags'
import { GoogleMap, useJsApiLoader ,Marker,Autocomplete} from '@react-google-maps/api';

// import Script from "next/script"
import './GoogleSearchBox.css'
export default function GoogleSearchBoxWithMap(props) {
  const {setLatitude,setLongitude,latitude,longitude,isItemLoading} = props
  const [searchResult,setSearchResult]=useState('')
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: String(process.env.GOOGLE_API_KEY),
    libraries:['places']
  })
  const containerStyle = {
    width: '100%',
    height: '400px'
  };
  
  const [location ,setLocation] = useState({lat:Number(latitude)||23.4241,lng:Number(longitude)||53.8478})

  const [map, setMap] = useState(null)

  const onLoad = useCallback(function callback(map) {
    // This is just an example of getting and using the map instance!!! don't just blindly copy!
    // const bounds = new window.google.maps.LatLngBounds(location);
    // map.fitBounds(bounds);
    map.setZoom(10)
    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback(map) {
    setMap(null)
  }, [])
  let onMapClicked =(e)=>{
    setLocation({lat:e.latLng.lat(),lng:e.latLng.lng()})
    setLatitude(e.latLng.lat())
    setLongitude(e.latLng.lng())
  }
  function onLoadd(autocomplete) {
    setSearchResult(autocomplete);
  }
  function onPlaceChanged() {
    if (searchResult != null) {
      const place = searchResult.getPlace();
      setLocation({lat:place?.geometry?.location?.lat(),lng:place?.geometry?.location?.lng()})
      setLatitude(place?.geometry?.location?.lat())
      setLongitude(place?.geometry?.location?.lng())
    } else {
      alert("Please enter text");
    }
  }
  useEffect(()=>{
    if(latitude&&longitude){
      setLocation({lat:Number(latitude),lng:Number(longitude)})
    }
},[latitude,longitude])
  return isLoaded ? (
    <>
    <Autocomplete onPlaceChanged={onPlaceChanged} onLoad={onLoadd}>
      <input
        type='text'
        data-kt-user-table-filter='search'
        className='form-control form-control-solid mb-5 ps-14'
        // placeholder={`${t('Square Urdu Name')}`}
        id='itemUrduName'
        // value={nameUr}
        onChange={(e) => console.log(e.target.value)}
      />
    </Autocomplete>
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={location}
      // zoom={5}
      onLoad={onLoad}
      onUnmount={onUnmount}
      onClick={e =>onMapClicked(e) }
    >
      <Marker position={location}/>
      { /* Child components, such as markers, info windows, etc. */ }
      <></>
    </GoogleMap>
    </>
) : <></>
}
