import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import styles from './AddEditModal.module.css'
import {ReactComponent as ExitIcon} from 'assets/icons/exit.svg'
import { useForm, Controller } from 'react-hook-form'
import { emailPattern } from 'utils/features'
import { axiosConfig } from 'utils/axiosConfig'
import { toast } from 'react-toastify'
import ButtonsLoading from 'components/Global/Elements/ButtonsLoading/ButtonsLoading'
import Cookies from 'js-cookie'
import Resizer from "react-image-file-resizer";
import { MultiSelect } from 'react-multi-select-component'
import './AddEditModal.css'

function AddEditTourGuidesModal({handleCloseAddUser ,showAddUser,user,getData, neighboorhoods, languages}) {
    const {register, setError, getValues, handleSubmit, control, setValue, formState:{errors}} = useForm({validate:'onChange'})
    const [isSubmitting , setIsSubmitting]=useState(false)
    const [image,setImage]=useState([])
    const [options,setOptions] = useState([]);
    useEffect(()=>{
        let options = languages?.map(language=>{
            return { label:language?.title, value:language?.id }
        })
        setOptions(options)
    }, [languages])

    async function handleUploadedImage(e){
        // let image = await new Promise((resolve) => {
        // Resizer.imageFileResizer(
        //     e.target.files[0],
        //     200,
        //     200,
        //     "JPEG",
        //     50,
        //     0,
        //     (uri) => {
        //         console.log(uri)
        //         resolve(uri);
        //     },
        //     "file",
        //     200,
        //     200,
        // );
        // });
        let image = Object.assign(e.target.files[0], {
            preview: URL.createObjectURL(e.target.files[0]),
        })
        setImage(image)
    }
    const submitForm =(data)=>{
        let formData =new FormData()
        Object.keys(getValues()).forEach(key=>{
            if(key !=='languages')
                formData.append(`${key}`,getValues()[key])
        })
        formData.append(`image`,image)
        getValues('languages')?.forEach((lang, index)=>{
            formData.append(`languages[${index}]`,lang?.value)
        })
        setIsSubmitting(true)
        if(user){
            axiosConfig.put(`tour-guides/update-tour-guide/${user?.id}`,formData,{
                headers: {"Authorization":`Bearer ${Cookies.get('token')}`}
            }).then(res=>{
                toast.success('Tour Guide Updated Successfully')
                handleCloseAddUser()
                setIsSubmitting(false)
                getData()
            }).catch(err=>{
                toast.error(err?.response?.data?.message||'Something went wrong')
                // handleCloseAddUser()
                setIsSubmitting(false)
            })
        }else{
            axiosConfig.post(`tour-guides/create-tour-guide`,formData,{
                headers: {"Authorization":`Bearer ${Cookies.get('token')}`}
            }).then(res=>{
                toast.success('Tour Guide Added Successfully')
                handleCloseAddUser()
                setIsSubmitting(false)
                getData()
            }).catch(err=>{
                // toast.error(err?.response?.data?.message||'Something went wrong')
                setIsSubmitting(false)
                let errors = err.response.data?.data?.[0]
                console.log(err.response.data?.data)
                Object?.keys(errors)?.forEach(error=>{
                    toast.error(errors[error])
                })
                // handleCloseAddUser()
            })

        }
    }
    useEffect(()=>{
        setValue('name',user?.name)
        setValue('email',user?.email)
        setValue('phone_number',user?.phone_number)
        setValue('neighborhood_id',user?.neighborhood_id)
        setValue('show_order_number',user?.show_order_number)
        setValue('is_available',user?.is_available)
        setImage(user?.image)
        // setValue('description',group?.description)
    }, [user])
    useEffect(()=>{
        if (user && languages?.length > 0) {
            let values = languages?.map((language) => {
                if (user?.languages_id?.includes(language?.id)) {
                    return { label:language?.title, value:language?.id }
                }
            })?.filter(lang=>lang)
            setValue('languages',values)
        }
        // setValue('description',group?.description)
    }, [user, languages])

    // useEffect(()=>{
    //     setValue('group_id',searchParams?.get('group'))
    // },[searchParams])
  return (
    <>
        <Modal show={showAddUser} onHide={handleCloseAddUser}  size="lg">
            <div className={`${styles["modal-header"]} modal-header`}>
                <h2 className={styles['modal__title']}>{user?'Update':'Add'} Tour Guide</h2>
                <button type="button" className={`${styles["close"]} close ms-auto`} onClick={handleCloseAddUser}>
                    <ExitIcon className={styles['modal__exit-icon']}/>
                </button>
            </div>
            <form onSubmit={handleSubmit(submitForm)}>
                <div className={`${styles["modal-body"]} modal-body`}>
                    <Row>
                        <Col lg='6' xs='12' className='mb-3'>
                            <div className={styles['modal__form-input-wrapper']}>
                                <label className={styles['modal__form-input-label']} htmlFor='userNameInput'>
                                    Name <span className={styles['modal__form-input-required']}>*</span>
                                </label>
                                <input 
                                    type='text' 
                                    className={`${styles['modal__form-input']} ${errors?.name ?styles['modal__form-input--error']:''}`} 
                                    placeholder='Please enter name' 
                                    id='userNameInput'
                                    {...register('name',{required:'name is required'})}
                                />
                                {errors?.name &&<span className={styles['modal__form-input-error-message']}>{errors.name?.message}</span>}
                            </div>
                        </Col>
                        <Col lg='6' xs='12' className='mb-3'>
                            <div className={styles['modal__form-input-wrapper']}>
                                <label className={styles['modal__form-input-label']} htmlFor='userPhoneNumberInput'>
                                    Phone Number <span className={styles['modal__form-input-required']}>*</span>
                                </label>
                                <input 
                                    type='text' 
                                    className={`${styles['modal__form-input']} ${errors?.name ?styles['modal__form-input--error']:''}`} 
                                    placeholder='Please enter phone number' 
                                    id='userPhoneNumberInput'
                                    {...register('phone_number',{required:'Phone number is required'})}
                                />
                                {errors?.phone_number &&<span className={styles['modal__form-input-error-message']}>{errors.phone_number?.message}</span>}
                            </div>
                        </Col>
                        <Col lg='6' xs='12' className='mb-3'>
                            <div className={styles['modal__form-input-wrapper']}>
                                <label className={styles['modal__form-input-label']} htmlFor='emailInput'>
                                    Email <span className={styles['modal__form-input-required']}>*</span>
                                </label>
                                <input 
                                    type='email' 
                                    className={`${styles['modal__form-input']} ${errors?.email ?styles['modal__form-input--error']:''}`}  
                                    placeholder='Please enter email' 
                                    id='emailInput'
                                    {...register('email',{required:'Email is required',
                                    pattern:{
                                        value:emailPattern,
                                        message:'Email must be like invo***@academy**.***'
                                    }})}
                                />
                                {errors?.email &&<span className={styles['modal__form-input-error-message']}>{errors.email?.message}</span>}
                            </div>
                        </Col>
                        <Col lg='6' xs='12' className='mb-3'>
                            <div className={styles['modal__form-input-wrapper']}>
                                <label className={styles['modal__form-input-label']} htmlFor='neighboorhoodInput'>
                                    Location
                                </label>
                                <select
                                    className={`${styles['modal__form-input']} px-2 ${errors?.neighborhood_id ?styles['modal__form-input--error']:''}`} 
                                    {...register('neighborhood_id')}
                                >
                                    <option value=''>Please Select Location</option>
                                    {
                                        neighboorhoods && neighboorhoods?.map(neighboorhood=>(
                                            <option value={neighboorhood?.id} key={neighboorhood?.id}>{neighboorhood?.title}</option>
                                        ))
                                    }
                                </select>
                                {errors?.neighborhood_id &&<span className={styles['modal__form-input-error-message']}>{errors.neighborhood_id?.message}</span>}
                            </div>
                        </Col>
                        <Col lg='6' xs='12' className='mb-3'>
                            <div className={`${styles['modal__form-input-wrapper']} h-100 `} id='langugeWrapperid'>
                                <label className={styles['modal__form-input-label']} htmlFor='languagesInput'>
                                    Languages
                                </label>
                                    <Controller 
                                        render={({name })=><MultiSelect
                                            className='h-100'
                                            name={name}
                                            options={options}
                                            value={getValues(`languages`)||[]}
                                            onChange={(data)=>  setValue(`languages`,data)}  
                                            labelledBy="Select"
                                        />}
                                        name='languages'
                                        control={control}
                                    />
                                {/* {errors?.languages &&<span className={styles['modal__form-input-error-message']}>{errors.languages?.message}</span>} */}
                            </div>
                        </Col>
                        <Col lg='6' xs='12' className='mb-3'>
                            <div className={styles['modal__form-input-wrapper']}>
                                <label className={styles['modal__form-input-label']} htmlFor='showOrderNumberInput'>
                                    Listing Number <span className={styles['modal__form-input-required']}>*</span>
                                </label>
                                <input 
                                    type='text' 
                                    className={`${styles['modal__form-input']} ${errors?.name ?styles['modal__form-input--error']:''}`} 
                                    placeholder='Please enter Listing Number' 
                                    id='showOrderNumberInput'
                                    {...register('show_order_number',{required:'Listing Number is required'})}
                                />
                                {errors?.show_order_number &&<span className={styles['modal__form-input-error-message']}>{errors.show_order_number?.message}</span>}
                            </div>
                        </Col>

                        <Col lg='6' xs='12' className='mb-3'>
                            <div className={styles['modal__form-input-wrapper']}>
                                <label className={styles['modal__form-input-label']} htmlFor='confirmPasswordInput'>
                                    Availability <span className={styles['modal__form-input-required']}>*</span>
                                </label>
                                <select
                                    className={`${styles['modal__form-input']} px-2 ${errors?.is_available ?styles['modal__form-input--error']:''}`} 
                                    {...register('is_available',{required:'User Availability is required'})}
                                >
                                    <option value='true' selected>Available</option>
                                    <option value='false'>Not Available</option>
                                </select>
                                {errors?.is_available &&<span className={styles['modal__form-input-error-message']}>{errors.is_available?.message}</span>}
                            </div>
                        </Col>
                        <Col xs='12' className='mb-3'>
                            <div className={styles['modal__form-input-wrapper']}>
                                <label className={styles['modal__form-input-label']} htmlFor='confirmPasswordInput'>
                                    Image 
                                </label>
                                <input
                                    type='file'
                                    data-kt-user-table-filter='search'
                                    className='form-control form-control-solid mb-3 ps-14'
                                    placeholder='Type Icon'
                                    id='carImage'
                                    onChange={(e) => handleUploadedImage(e)}
                                    accept={'.jpg,.png,.gif,.jpeg'}
                                />
                                {
                                    image&&(image?.preview||image?.length!=0)&&
                                        <div>
                                            <div className='mb-2 d-flex align-items-center justify-content-between'>
                                                <img src={image?.preview ?image?.preview:image} alt='img' style={{width:'100px',height:'100px'}}/>
                                                <button onClick={()=>{setImage(null)}}
                                                className='btn btn-danger ms-auto'>Delete</button>
                                            </div>
                                        </div>
                                }
                                {/* {errors?.status &&<span className={styles['modal__form-input-error-message']}>{errors.status?.message}</span>} */}
                            </div>
                        </Col>
                    </Row>
                    <div className={styles['submit-modal__buttons-cont']}>
                        <button type='submit' className={styles['submit-modal__confirm-button']} disabled={isSubmitting}>{isSubmitting?<ButtonsLoading/>:'Submit'}</button>
                    </div>
                </div>
            </form>

        </Modal>
    </>
  )
}

export default AddEditTourGuidesModal