import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { Col } from 'react-bootstrap'; // Assuming you are using react-bootstrap for the Col component
import styles from './MultiLanguageDescriptionAdvancedInput.module.css'; // Replace with your actual CSS module path

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const MultiLanguageDescriptionAdvancedInput = ({keyValue})=> {
  const { register, setValue, watch ,getValues, formState: { errors } } = useFormContext(); // Access form context
  watch();
  useEffect(() => {
    const subscription = watch((value, { name, type }) => console.log(value, name, type));
    return () => subscription.unsubscribe();
  }, [watch]);
  return (
    <>
    {
      keyValue &&
        <>
          <Col lg='6' xs='12' className='mb-3'>
            <div className={styles['modal__form-input-wrapper']}>
              <label className={styles['modal__form-input-label']} htmlFor={`${keyValue}En`}>
                English Description <span className={styles['modal__form-input-required']}>*</span>
              </label>
              <ReactQuill
                  className={' mb-5 '}
                  onChange={(data)=>setValue(`${keyValue}.en`, data)}
                  value={getValues(`${keyValue}`)?.en}
                  style={{height:'300px'}}
                  theme="snow" 
                />
              <input
                type="hidden"
                id={`${keyValue}En`}
                {...register(`${keyValue}.en`, { required: 'English description is required' })}
              />
              {errors?.[keyValue]?.en && <span className={styles['modal__form-input-error-message']}>{errors?.[keyValue]?.en.message}</span>}
            </div>
          </Col>

          <Col lg='6' xs='12' className='mb-3'>
            <div className={styles['modal__form-input-wrapper']}>
              <label className={styles['modal__form-input-label']} htmlFor={`${keyValue}Ar`}>
                Arabic Description <span className={styles['modal__form-input-required']}>*</span>
              </label>
              <ReactQuill
                  className={' mb-5 '}
                  onChange={(data)=>setValue(`${keyValue}.ar`, data)}
                  value={getValues(`${keyValue}`)?.ar}
                  style={{height:'300px'}}
                  theme="snow" 
                />
              <input
                type="hidden"
                id={`${keyValue}Ar`}
                {...register(`${keyValue}.ar`, { required: 'Arabic description is required' })}
              />
              {errors?.[keyValue]?.ar && <span className={styles['modal__form-input-error-message']}>{errors?.[keyValue]?.ar?.message}</span>}
            </div>
          </Col>

          <Col lg='6' xs='12' className='mb-3'>
            <div className={styles['modal__form-input-wrapper']}>
              <label className={styles['modal__form-input-label']} htmlFor={`${keyValue}Fr`}>
                French Description <span className={styles['modal__form-input-required']}>*</span>
              </label>
              <ReactQuill
                  className={' mb-5 '}
                  onChange={(data)=>setValue(`${keyValue}.fr`, data)}
                  value={getValues(`${keyValue}`)?.fr}
                  style={{height:'300px'}}
                  theme="snow" 
                />
              <input
                type="hidden"
                id={`${keyValue}Fr`}
                {...register(`${keyValue}.fr`, { required: 'French description is required' })}
              />
              {/* <input
                type='text'
                className={`${styles['modal__form-input']} ${errors?.[keyValue]?.fr ? styles['modal__form-input--error'] : ''}`}
                placeholder='Please Enter French Description'
                id={`${keyValue}Fr`}
                {...register(`${keyValue}.fr`, { required: 'French description is required' })}
              /> */}
              {errors?.[keyValue]?.fr && <span className={styles['modal__form-input-error-message']}>{errors?.[keyValue]?.fr?.message}</span>}
            </div>
          </Col>

          <Col lg='6' xs='12' className='mb-3'>
            <div className={styles['modal__form-input-wrapper']}>
              <label className={styles['modal__form-input-label']} htmlFor={`${keyValue}De`}>
                German Description <span className={styles['modal__form-input-required']}>*</span>
              </label>
              <ReactQuill
                  className={' mb-5 '}
                  onChange={(data)=>setValue(`${keyValue}.de`, data)}
                  value={getValues(`${keyValue}`)?.de}
                  style={{height:'300px'}}
                  theme="snow" 
                />
              <input
                type="hidden"
                id={`${keyValue}De`}
                {...register(`${keyValue}.de`, { required: 'German description is required' })}
              />
              {/* <input
                type='text'
                className={`${styles['modal__form-input']} ${errors?.[keyValue]?.de ? styles['modal__form-input--error'] : ''}`}
                placeholder='Please Enter German Description'
                id={`${keyValue}De`}
                {...register(`${keyValue}.de`, { required: 'German description is required' })}
              /> */}
              {errors?.[keyValue]?.de && <span className={styles['modal__form-input-error-message']}>{errors?.[keyValue]?.de?.message}</span>}
            </div>
          </Col>

          <Col lg='6' xs='12' className='mb-3'>
            <div className={styles['modal__form-input-wrapper']}>
              <label className={styles['modal__form-input-label']} htmlFor={`${keyValue}Es`}>
                Spanish Description <span className={styles['modal__form-input-required']}>*</span>
              </label>
              <ReactQuill
                  className={' mb-5 '}
                  onChange={(data)=>setValue(`${keyValue}.es`, data)}
                  value={getValues(`${keyValue}`)?.es}
                  style={{height:'300px'}}
                  theme="snow" 
                />
              <input
                type="hidden"
                id={`${keyValue}Es`}
                {...register(`${keyValue}.es`, { required: 'Spanish description is required' })}
              />
              {/* <input
                type='text'
                className={`${styles['modal__form-input']} ${errors?.[keyValue]?.es ? styles['modal__form-input--error'] : ''}`}
                placeholder='Please Enter Spanish Description'
                id={`${keyValue}Es`}
                {...register(`${keyValue}.es`, { required: 'Spanish description is required' })}
              /> */}
              {errors?.[keyValue]?.es && <span className={styles['modal__form-input-error-message']}>{errors?.[keyValue]?.es?.message}</span>}
            </div>
          </Col>

          <Col lg='6' xs='12' className='mb-3'>
            <div className={styles['modal__form-input-wrapper']}>
              <label className={styles['modal__form-input-label']} htmlFor={`${keyValue}Tr`}>
                Turkish Description <span className={styles['modal__form-input-required']}>*</span>
              </label>
              <ReactQuill
                  className={' mb-5 '}
                  onChange={(data)=>setValue(`${keyValue}.tr`, data)}
                  value={getValues(`${keyValue}`)?.tr}
                  style={{height:'300px'}}
                  theme="snow" 
                />
              <input
                type="hidden"
                id={`${keyValue}Tr`}
                {...register(`${keyValue}.tr`, { required: 'Turkish description is required' })}
              />
              {/* <input
                type='text'
                className={`${styles['modal__form-input']} ${errors?.[keyValue]?.tr ? styles['modal__form-input--error'] : ''}`}
                placeholder='Please Enter Turkish Description'
                id={`${keyValue}Tr`}
                {...register(`${keyValue}.tr`, { required: 'Turkish description is required' })}
              /> */}
              {errors?.[keyValue]?.tr && <span className={styles['modal__form-input-error-message']}>{errors?.[keyValue]?.tr?.message}</span>}
            </div>
          </Col>
        </>
    }
    </>
  );
};

export default MultiLanguageDescriptionAdvancedInput;
