import React, { useState } from 'react'
import styles from './PortaTable.module.css'
import {ReactComponent as EditIcon} from 'assets/icons/editIcon.svg'
import {ReactComponent as DeleteIcon} from 'assets/icons/delete.svg'
import AddEditUserModal from '../AddEditModal/AddEditModal'
import { axiosConfig } from 'utils/axiosConfig'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import TableLoading from 'components/Global/Elements/TableLoading/TableLoading'

function PortalAdminUsersTable({userData, getData, isLoadingData}) {
    const [showAddUser, setShowAddUser] = useState(false);
    const [user,setuser] =useState([])

    const handleCloseAddUser = () => {
        setShowAddUser(false)
        setuser([])
    };
    const handleShowAddUser = (user) => {
        setShowAddUser(true)
        setuser(user)
    };
    const deleteUser =(userId)=>{
        axiosConfig.delete(`admin/delete-user/${userId}`,{
            headers: {"Authorization":`Bearer ${Cookies.get('token')}`}
        }).then(res=>{
            toast.success('User deleted successfully')
            getData()
        }).catch(err=>{
            // setIsSubmitting(false)
            let errors = err?.response?.data?.data?.[0]
            console.log(err?.response?.data?.data)
            Object.keys(errors)?.forEach(error=>{
                toast.error(errors?.[error])
            })
        })
    }
  return (
    <>
    <div className='table-responsive'>
        <table className={`${styles['table']} table`}>
            <thead>
                <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">Status</th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                {
                    !isLoadingData ?
                        userData && userData?.map(user=>(
                            <tr key={user?.id}>
                                <th scope="row">{user?.item_number}</th>
                                
                                <td>
                                    <div className={`${styles['table__user-group']} fw-bold`}>{user?.name}</div>
                                </td>
                                <td>
                                    <div className={`${styles['table__user-group']} text-muted`}>{user?.email}</div>
                                </td>
                                <td>
                                    {
                                        <>
                                            {/* <p className={`${styles['table__user-status']} ${user?.status ==0?'':'d-none'}`}>
                                                <span className={`${styles['table__user-status-color']} ${styles['table__user-status-color--new']}`}></span>
                                                <span className={styles['table__user-status-value']}>New</span>
                                            </p> */}
                                            <p className={`${styles['table__user-status']} ${user?.status === 1?'':'d-none'}`}>
                                                <span className={`${styles['table__user-status-color']} ${styles['table__user-status-color--active']}`}></span>
                                                <span className={styles['table__user-status-value']}>Active</span>
                                            </p>
                                            <p className={`${styles['table__user-status']} ${user?.status === 2?'':'d-none'}`}>
                                                <span className={`${styles['table__user-status-color']} ${styles['table__user-status-color--blocked']}`}></span>
                                                <span className={styles['table__user-status-value']}>Not Active</span>
                                            </p>
                                        </>
                                    }
                                </td>
                                {/* <td>
                                    <div className={styles['table__user-status-cont']}>
                                        <Link to={`/portal/test-results?student=${user?.id}`} className={`${styles['table__user-add-section-buttons']}`}>Test Results</Link>
                                    </div>
                                </td> */}
                                {/* <td>
                                    <div className={styles['table__user-status-cont']}>
                                        <button className={`${styles['table__user-status-buttons']} 
                                        ${styles['table__user-status-buttons--active']} ${user?.status !=1?'':'d-none'}`}>Active</button>

                                        <button className={`${styles['table__user-status-buttons']} 
                                        ${styles['table__user-status-buttons--blocked']} ${user?.status ==1?'':'d-none'}`}>Blocked</button>

                                    </div>
                                </td> */}
                                <td>
                                    <div className={styles['table__user-action-cont']}>
                                        <button className={styles['table__user-action-button']} onClick={()=>{handleShowAddUser(user)}}>
                                            <EditIcon className={`${styles['table__user-action-icon']} ${styles['table__user-action-icon--edit']}`}/>
                                        </button>
                                        <button className={styles['table__user-action-button']} onClick={()=>{deleteUser(user?.id)}}>
                                            <DeleteIcon className={`${styles['table__user-action-icon']} ${styles['table__user-action-icon--delete']}`}/>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))
                    :  <></>
                }
            </tbody>
        </table>
        <AddEditUserModal showAddUser={showAddUser} handleCloseAddUser={handleCloseAddUser} user={user} getData={getData}/>
    </div>
    {
        isLoadingData && <TableLoading/>
    }
    </>
  )
}

export default PortalAdminUsersTable