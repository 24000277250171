import React, { useState } from 'react'
import styles from './PortalTable.module.css'
import {ReactComponent as EditIcon} from 'assets/icons/editIcon.svg'
import {ReactComponent as DeleteIcon} from 'assets/icons/delete.svg'
import { axiosConfig } from 'utils/axiosConfig'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import defaultUserImage from 'assets/imgs/defaultUserImage.png'
import TableLoading from 'components/Global/Elements/TableLoading/TableLoading'
import AddEditRecommendedProgramsModal from '../AddEditModal/AddEditModal'

function PortalRecommendedProgramsTable({userData, getData, isLoadingData, places}) {
    const [showAddUser, setShowAddUser] = useState(false);
    const [user,setuser] =useState([])

    const handleCloseAddUser = () => {
        setShowAddUser(false)
        setuser([])
    };
    const handleShowAddUser = (user) => {
        setShowAddUser(true)
        setuser(user)
    };
    const deleteUser =(userId)=>{
        axiosConfig.delete(`recommended-programs/delete-recommended-program/${userId}`,{
            headers: {"Authorization":`Bearer ${Cookies.get('token')}`}
        }).then(res=>{
            toast.success('Recommended Programs deleted successfully')
            getData()
        }).catch(err=>{
            toast.error(err?.response?.data?.message||'Something went wrong')
        })
    }
  return (
    <>
    <div className='table-responsive'>
        <table className={`${styles['table']} table`}>
            <thead>
                <tr>
                    <th scope="col">Id</th>
                    <th scope="col">Image</th>
                    <th scope="col">Title</th>
                    <th scope="col">Days</th>
                    <th scope="col">Listing Number</th>
                    <th scope="col">Actions</th>
                </tr>
            </thead>
            <tbody>
                {
                    !isLoadingData ?
                        userData && userData?.map(user=>(
                            <tr key={user?.id}>
                                <th scope="row">{user?.item_number}</th>
                                <td>
                                    <div className={styles['table__user-img-wrapper']}>
                                        <img 
                                            src={user?.image?user?.image:defaultUserImage} 
                                            className={styles['table__user-img']}
                                            alt='user'
                                        />
                                    </div>
                                </td>
                                <td>
                                    <div className={`${styles['table__user-group']} fw-bold`}>{user?.title}</div>
                                </td>
                                <td>
                                    <div className={`${styles['table__user-group']} text-muted`}>{user?.days}</div>
                                </td>
                                <td>
                                    <div className={`${styles['table__user-group']} text-muted`}>{user?.show_order_number}</div>
                                </td>
                                {/* <td>
                                    <div className={styles['table__user-status-cont']}>
                                        <Link to={`/portal/test-results?student=${user?.id}`} className={`${styles['table__user-add-section-buttons']}`}>Test Results</Link>
                                    </div>
                                </td> */}
                                {/* <td>
                                    <div className={styles['table__user-status-cont']}>
                                        <button className={`${styles['table__user-status-buttons']} 
                                        ${styles['table__user-status-buttons--active']} ${user?.status !=1?'':'d-none'}`}>Active</button>

                                        <button className={`${styles['table__user-status-buttons']} 
                                        ${styles['table__user-status-buttons--blocked']} ${user?.status ==1?'':'d-none'}`}>Blocked</button>

                                    </div>
                                </td> */}
                                <td>
                                    <div className={styles['table__user-action-cont']}>
                                        <button className={styles['table__user-action-button']} onClick={()=>{handleShowAddUser(user)}}>
                                            <EditIcon className={`${styles['table__user-action-icon']} ${styles['table__user-action-icon--edit']}`}/>
                                        </button>
                                        <button className={styles['table__user-action-button']} onClick={()=>{deleteUser(user?.id)}}>
                                            <DeleteIcon className={`${styles['table__user-action-icon']} ${styles['table__user-action-icon--delete']}`}/>
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))
                    :  <></>
                }
            </tbody>
        </table>
        <AddEditRecommendedProgramsModal
            showAddUser={showAddUser}
            handleCloseAddUser={handleCloseAddUser}
            user={user}
            getData={getData}
            places={places}
        />

    </div>
    {
        isLoadingData && <TableLoading/>
    }
    </>
  )
}

export default PortalRecommendedProgramsTable