import React, { useEffect, useState } from 'react'
import { Row } from 'react-bootstrap'
import styles from './AddEditModal.module.css'
import { FormProvider, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import ButtonsLoading from 'components/Global/Elements/ButtonsLoading/ButtonsLoading'
import Cookies from 'js-cookie'
import { axiosConfig } from 'utils/axiosConfig'
import MultiLanguageDescriptionAdvancedInput from 'components/Global/Elements/MultiLanguageDescriptionAdvancedInput/MultiLanguageDescriptionAdvancedInput'

function AddEditStaticPagesForm({ handleCloseAddUser, showAddUser, settings, getData }) {
    const methods = useForm({ validate: 'onChange' })
    const { register, handleSubmit, getValues, setValue, formState: { errors } } = methods
    const [isSubmitting, setIsSubmitting] = useState(false)
    const submitForm = (data) => {
        setIsSubmitting(true)
        let formData =new FormData()
        formData.append(`privacy_policy_en`,getValues()?.['privacy_policy']?.['en'])
        formData.append(`privacy_policy_ar`,getValues()?.['privacy_policy']?.['ar'])
        formData.append(`privacy_policy_fr`,getValues()?.['privacy_policy']?.['fr'])
        formData.append(`privacy_policy_de`,getValues()?.['privacy_policy']?.['de'])
        formData.append(`privacy_policy_es`,getValues()?.['privacy_policy']?.['es'])
        formData.append(`privacy_policy_tr`,getValues()?.['privacy_policy']?.['tr'])        
        formData.append(`terms_and_conditionds_en`,getValues()?.['terms_and_conditionds']?.['en'])
        formData.append(`terms_and_conditionds_ar`,getValues()?.['terms_and_conditionds']?.['ar'])
        formData.append(`terms_and_conditionds_fr`,getValues()?.['terms_and_conditionds']?.['fr'])
        formData.append(`terms_and_conditionds_de`,getValues()?.['terms_and_conditionds']?.['de'])
        formData.append(`terms_and_conditionds_es`,getValues()?.['terms_and_conditionds']?.['es'])
        formData.append(`terms_and_conditionds_tr`,getValues()?.['terms_and_conditionds']?.['tr'])
        formData.append(`about_us_en`,getValues()?.['about_us']?.['en'])
        formData.append(`about_us_ar`,getValues()?.['about_us']?.['ar'])
        formData.append(`about_us_fr`,getValues()?.['about_us']?.['fr'])
        formData.append(`about_us_de`,getValues()?.['about_us']?.['de'])
        formData.append(`about_us_es`,getValues()?.['about_us']?.['es'])
        formData.append(`about_us_tr`,getValues()?.['about_us']?.['tr'])
        
        axiosConfig.put('static-pages/update-static-pages', formData, {
            headers: { "Authorization": `Bearer ${Cookies.get('token')}` }
        }).then(res => {
            toast.success('Static Pages Updated Successfully')
            setIsSubmitting(false)
            getData()
        }).catch(err => {
            toast.error(err?.response?.data?.message || 'Something went wrong')
            setIsSubmitting(false)
        })
    }

    useEffect(() => {
        setValue('terms_and_conditionds', settings?.terms_and_conditionds)
        setValue('about_us', settings?.about_us)
        setValue('privacy_policy', settings?.privacy_policy)
    }, [settings])

    return (
        <div className='table-responsive'>
            <div className={`${styles["modal-header"]} modal-header`}>
                <h2 className={styles['modal__title']}>Update Static Pages</h2>
            </div>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(submitForm)}>
                    <div className={`${styles["modal-body"]} modal-body`}>
                        <Row>
                            <h2>Privacy Policy</h2>
                            <MultiLanguageDescriptionAdvancedInput keyValue="privacy_policy"/>
                            <h2>Terms And Conditionds</h2>
                            <MultiLanguageDescriptionAdvancedInput keyValue="terms_and_conditionds"/>
                            <h2>About Us</h2>
                            <MultiLanguageDescriptionAdvancedInput keyValue="about_us"/>
                        </Row>
                    </div>
                    <div className={styles['submit-modal__buttons-cont']}>
                        <button type='submit' className={styles['submit-modal__confirm-button']} disabled={isSubmitting}>{isSubmitting?<ButtonsLoading/>:'Submit'}</button>
                    </div>
                </form>

            </FormProvider>
        </div>
    )
}

export default AddEditStaticPagesForm;
