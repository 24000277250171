import React from 'react'
import styles from './ListingHeader.module.css'
import {ReactComponent as LisingIcon} from 'assets/icons/listing.svg'
function ListingHeader() {
  return (
    <>
      <div className={styles['page-header']}>
          <LisingIcon className={styles['page-header__icon']}/>
          <span className={styles['page-header__text']}>Listing</span>
      </div>
    </>
  )
}

export default ListingHeader