import React, { useEffect, useState } from 'react'
import styles from './StaticPages.module.css'
import { axiosConfig } from 'utils/axiosConfig'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import ListingHeader from 'components/Global/Elements/ListingHeader/ListingHeader'
import AddEditStaticPagesForm from 'components/Portal/StaticPages/AddEditModal/AddEditModal'

function StaticPages() {

    const [userData ,setUserData] =useState(null)
    const [isLoadingData ,setIsLoadingData] =useState(false)
    
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        getData(page)
    };

    const getData =()=>{
        setIsLoadingData(true)
        axiosConfig.get(`static-pages/all`,{
            headers: {"Authorization":`Bearer ${Cookies.get('token')}`}
        }).then(res=>{
            setIsLoadingData(false)
            // setCategories(res.data.data)
            setUserData(res?.data?.data)
        }).catch(err=>{
            setIsLoadingData(false)
            let errors = err.response.data.errors
            Object.keys(errors).forEach(error=>{
            toast.error(errors[error][0])
            })
        })
    }
    useEffect(()=>{
        getData()
    }, [])

    return (
        <section>
            <ListingHeader/>
            <div className={styles['table-cont']}>
                <div className={styles['table__header-wrapper']}>
                    <h1 className={styles['table__title']}>Static Pages</h1>
                    {/* <UserTableHeader getData={getData}/> */}
                </div>
                <AddEditStaticPagesForm settings={userData} getData={getData} isLoadingData={isLoadingData}/>
                {/* <PortalTablePagination/> */}
                {/* <PortalTablePagination
                    totalPages={totalPages}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                /> */}
            </div>
        </section>
    )
}

export default StaticPages