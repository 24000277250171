import React, { useEffect, useState } from 'react'
import styles from './Languages.module.css'
import PortalTablePagination from 'components/Global/Elements/PortalTablePagination/PortalTablePagination'
import { useDispatch, useSelector } from 'react-redux'
import { axiosConfig } from 'utils/axiosConfig'
import { toast } from 'react-toastify'
import Cookies from 'js-cookie'
import { saveCategories } from 'store/Login/LoginActions'
import LanguagesTableHeader from 'components/Portal/Languages/TableHeader/TableHeader'
import PortalLanguagesTable from 'components/Portal/Languages/PortalTable/PortalTable'

function Languages() {
    const user = useSelector(state=>state.LoginReducer?.user)
    const dispatch = useDispatch()
    const [tableData ,setTableData] =useState([]) 

    const [isLoadingData ,setIsLoadingData] =useState(false)
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const handlePageChange = (page) => {
        setCurrentPage(page);
        getData(page)
    };

    const getData =(page)=>{
        setIsLoadingData(true)
        // axiosConfig.get(`exams?user_id=${user.id}`).then(res=>{
        axiosConfig.get(`languages/all-languages-with-pagination?page=${page}`,{
            headers: {"Authorization":`Bearer ${Cookies.get('token')}` , 'accept-language': 'en'}
        }).then(res=>{
            setIsLoadingData(false)
            setTableData(res?.data?.data?.data)
            setTotalPages(res?.data?.data?.pages)
            dispatch(saveCategories(res?.data?.data?.data))
        }).catch(err=>{
            setIsLoadingData(false)
            let errors = err.response.data.errors
            Object.keys(errors).forEach(error=>{
                toast.error(errors[error][0])
            })
        })
    }
    useEffect(()=>{
        getData(1)
    }, [user])

  return (
    <section>
        <div className={styles['table-cont']}>
            <div className={styles['table__header-wrapper']}>
                <h1 className={styles['table__title']}>Languages</h1>
                <LanguagesTableHeader getData={getData}/>
            </div>
            
            <PortalLanguagesTable tableData={tableData} getData={getData} isLoadingData={isLoadingData}/>
            <PortalTablePagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
            />
        </div>
    </section>
  )
}

export default Languages