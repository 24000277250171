import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Col } from 'react-bootstrap'; // Assuming you are using react-bootstrap for the Col component
import styles from './MultiLanguageDescriptionInput.module.css'; // Replace with your actual CSS module path

const MultiLanguageDescriptionInput = () => {
  const { register, formState: { errors } } = useFormContext(); // Access form context

  return (
    <>
      <Col lg='6' xs='12' className='mb-3'>
        <div className={styles['modal__form-input-wrapper']}>
          <label className={styles['modal__form-input-label']} htmlFor='descriptionEn'>
            English Description <span className={styles['modal__form-input-required']}>*</span>
          </label>
          <input
            type='text'
            className={`${styles['modal__form-input']} ${errors?.descriptions?.en ? styles['modal__form-input--error'] : ''}`}
            placeholder='Please Enter English Description'
            id='descriptionEn'
            {...register('descriptions.en', { required: 'English description is required' })}
          />
          {errors?.descriptions?.en && <span className={styles['modal__form-input-error-message']}>{errors?.descriptions?.en.message}</span>}
        </div>
      </Col>

      <Col lg='6' xs='12' className='mb-3'>
        <div className={styles['modal__form-input-wrapper']}>
          <label className={styles['modal__form-input-label']} htmlFor='descriptionAr'>
            Arabic Description <span className={styles['modal__form-input-required']}>*</span>
          </label>
          <input
            type='text'
            className={`${styles['modal__form-input']} ${errors?.descriptions?.ar ? styles['modal__form-input--error'] : ''}`}
            placeholder='Please Enter Arabic Description'
            id='descriptionAr'
            {...register('descriptions.ar', { required: 'Arabic description is required' })}
          />
          {errors?.descriptions?.ar && <span className={styles['modal__form-input-error-message']}>{errors?.descriptions?.ar?.message}</span>}
        </div>
      </Col>

      <Col lg='6' xs='12' className='mb-3'>
        <div className={styles['modal__form-input-wrapper']}>
          <label className={styles['modal__form-input-label']} htmlFor='descriptionFr'>
            French Description <span className={styles['modal__form-input-required']}>*</span>
          </label>
          <input
            type='text'
            className={`${styles['modal__form-input']} ${errors?.descriptions?.fr ? styles['modal__form-input--error'] : ''}`}
            placeholder='Please Enter French Description'
            id='descriptionFr'
            {...register('descriptions.fr', { required: 'French description is required' })}
          />
          {errors?.descriptions?.fr && <span className={styles['modal__form-input-error-message']}>{errors?.descriptions?.fr?.message}</span>}
        </div>
      </Col>

      <Col lg='6' xs='12' className='mb-3'>
        <div className={styles['modal__form-input-wrapper']}>
          <label className={styles['modal__form-input-label']} htmlFor='descriptionDe'>
            German Description <span className={styles['modal__form-input-required']}>*</span>
          </label>
          <input
            type='text'
            className={`${styles['modal__form-input']} ${errors?.descriptions?.de ? styles['modal__form-input--error'] : ''}`}
            placeholder='Please Enter German Description'
            id='descriptionDe'
            {...register('descriptions.de', { required: 'German description is required' })}
          />
          {errors?.descriptions?.de && <span className={styles['modal__form-input-error-message']}>{errors?.descriptions?.de?.message}</span>}
        </div>
      </Col>

      <Col lg='6' xs='12' className='mb-3'>
        <div className={styles['modal__form-input-wrapper']}>
          <label className={styles['modal__form-input-label']} htmlFor='descriptionEs'>
            Spanish Description <span className={styles['modal__form-input-required']}>*</span>
          </label>
          <input
            type='text'
            className={`${styles['modal__form-input']} ${errors?.descriptions?.es ? styles['modal__form-input--error'] : ''}`}
            placeholder='Please Enter Spanish Description'
            id='descriptionEs'
            {...register('descriptions.es', { required: 'Spanish description is required' })}
          />
          {errors?.descriptions?.es && <span className={styles['modal__form-input-error-message']}>{errors?.descriptions?.es?.message}</span>}
        </div>
      </Col>

      <Col lg='6' xs='12' className='mb-3'>
        <div className={styles['modal__form-input-wrapper']}>
          <label className={styles['modal__form-input-label']} htmlFor='descriptionTr'>
            Turkish Description <span className={styles['modal__form-input-required']}>*</span>
          </label>
          <input
            type='text'
            className={`${styles['modal__form-input']} ${errors?.descriptions?.tr ? styles['modal__form-input--error'] : ''}`}
            placeholder='Please Enter Turkish Description'
            id='descriptionTr'
            {...register('descriptions.tr', { required: 'Turkish description is required' })}
          />
          {errors?.descriptions?.tr && <span className={styles['modal__form-input-error-message']}>{errors?.descriptions?.tr?.message}</span>}
        </div>
      </Col>
    </>
  );
};

export default MultiLanguageDescriptionInput;
